.header__button {
    padding: 0;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    color: #A9A9A9;
    border: none;
    background: none;
    text-decoration: none;
    cursor: pointer;
    transition: all .3s ease;
}

.header__button:hover {
    color: #fff;
}