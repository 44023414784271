.element__like {
    opacity: 1;
    transition: all .3s ease;
    flex-shrink: 0;
    width: 22px;
    height: 19px;
    background-image: url(../../../images/like.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    background-color: #fff;
    border: none;
}

.element__like:hover {
    opacity: .5;
    cursor: pointer;
}
