.popup__input-error {
    margin: 5px 0 0;
    min-height: 30px;
    display: block;
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.2;
    color: #FF0000;
}