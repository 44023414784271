.profile__add-button {
    min-width: 150px;
    height: 50px;
    border: 2px solid #fff;
    border-radius: 2px;
    box-sizing: border-box;
    background-image: url(../../../images/plus.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 22px 22px;
    background-color: rgba(0, 0, 0, 0);
    transition: opacity .3s ease;
}

.profile__add-button:hover {
    opacity: .6;
    cursor: pointer;
}


@media screen and (max-width: 768px) {
    .profile__add-button {
        width: 282px;
        background-size: 16px 16px;
    }
}