.popup__title {
    margin: 0 0 54px 0;
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 1.2;
    color: #000;
}

@media screen and (max-width: 768px) {
    .popup__title {
        margin: 0 0 75px 0;
        font-size: 18px;
    }
}