.popup__container {
    position: relative;
    margin: auto;
    padding: 35px;
    width: 100%;
    max-width: 430px;
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 470px) {  /*430px + 20px + 20px*/
    .popup__container {
        margin: auto 20px;
    }
}

@media screen and (max-width: 768px) {
    .popup__container {
        padding: 25px 22px;
    }
}