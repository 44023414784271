.profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .profile {
        flex-direction: column;
    }
}
