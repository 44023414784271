.profile__about-me {
    width: 100%;
    margin: 0;
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.22;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .profile__about-me {
        font-size: 14px;
        line-height: 1.21;
        text-align: center;
        max-width: 282px;
    }
}