.container__elements {
    margin: 50px 0 0 0;
}

@media screen and (max-width: 992px) {
    .container__elements {
        margin: 50px 19px 0;
    }
}

@media screen and (max-width: 768px) {
    .container__elements {
        margin: 36px 19px 0;
    }
}