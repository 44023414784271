.profile__info {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
}

@media screen and (max-width: 768px) {
    .profile__info {
        margin: 0 0  36px;
    }

}