.header__logo {
    margin-bottom: 41px;
}

@media screen and (max-width: 768px) {
    .header__logo {
        width: 104px;
        height: 25px;
    }
}

@media screen and (max-width: 991px) {
    .header__logo {
        margin: 0 27px 31px;
    }
}