.popup {
    display: flex;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    transition: visibility 0s linear .3s, opacity .3s linear;
    z-index: -1;
}
