.profile__edit-button {
    min-width: 24px;
    height: 24px;
    background-image: url(../../../images/edit.svg);
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    background-size: 10px 10px;
    background-position: center;
    transition: opacity .3s ease;
    border: 1px solid #fff;
}

.profile__edit-button:hover {
    cursor: pointer;
    opacity: 0.6;
}

@media screen and (max-width: 768px) {
    .profile__edit-button {
        margin: 0 0 0 10px;
        min-width: 18px;
        height: 18px;
    }
}