.enter__button {
    width: 100%;
    height: 50px;
    background-color: #fff;
    border: none;
    border-radius: 2px;
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.22;
    text-align: center;
    color: #000;
    transition: opacity .3s ease;
    margin-bottom: 20px;
}

.enter__button:hover {
    opacity: .8;
    cursor: pointer;
}

.enter__button:disabled {
    opacity: .6;
}

.enter__button:disabled:hover {
    opacity: .6;
}

@media screen and (max-width: 768px) {
    .enter__button {
        height: 46px;
        font-size: 16px;
        line-height: 1.21;
    }
}