.profile__name-text {
    margin: 0 18px 0 0;
    font-size: 42px;
    line-height: 1.14;
    color: #fff;
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 508px;
}

@media screen and (max-width: 768px) {
    .profile__name-text {
        margin: 0;
        font-size: 27px;
        line-height: 1.2;
        max-width: calc(282px - 18px - 10px);
    }
}