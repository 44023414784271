.profile__name {
    margin: 0 0 16px;
    display: flex;
    align-items: baseline;
}

@media screen and (max-width: 768px) {
    .profile__name {
        margin: 26px 0 14px;
        justify-content: center;
    }
}