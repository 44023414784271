.enter__title {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 1;
    text-align: center;
    color: #fff;
    margin: 0 0 50px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .enter__title {
        margin: 0 0 40px;
    }
}