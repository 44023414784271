.popup__input {
    margin: 0;
    padding: 5px;
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.2;
    color: #000;
}

@media screen and (max-width: 768px) {
    .popup__input:last-of-type {
        margin: 0 0 45px;
    }
}