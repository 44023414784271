.enter.container__enter {
    margin-top: 60px;
    margin-bottom: 40px;
    padding: 0 30px
}

@media screen and (max-width: 768px) {
    .enter.container__enter {
        margin-top: 40px;
    }
}