.popup__submit-button {
    margin-top: 10px;
    width: 100%;
    height: 50px;
    background-color: #000;
    border: none;
    border-radius: 2px;
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.22;
    text-align: center;
    color: #fff;
    transition: opacity .3s ease;
}

.popup__submit-button:hover {
    opacity: .8;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .popup__submit-button {
        height: 46px;
        font-size: 14px;
        line-height: 1.21;
    }
}