.footer__text {
    margin: 0;
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.2;
    color: #545454;
}

@media screen and (max-width: 768px) {
    .footer__text {
        font-size: 14px;
        line-height: 1.2;
    }
}