.profile__edit-avatar-button {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: none;
    margin: auto;
    background-image: url(../../../images/edit.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(0, 0, 0, 0);
    background-size: 26px;
    cursor: pointer;
    opacity: 0;
    transition: opacity .3s ease;
}

.profile__edit-avatar-button:hover {
    opacity: 1;
}
